// src/components/AboutMe.js
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  color: #FFF;
  background-color: #282c34;
  padding: 20px;
  font-family: 'Arial', sans-serif;
`;

const Section = styled.section`
  margin-bottom: 20px;
`;

const Heading = styled.h1`
  color: #61dafb;
`;

const SubHeading = styled.h2`
  color: #aad1e6;
  font-size: 1em;
`;

const Text = styled.p`
  line-height: 1.6;
`;

const SubText = styled.p`
  color: #bababa;
  font-size: 0.8em;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  &:before {
    content: '● ';
    color: #61dafb;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

function AboutMe() {
  return (
    <Container>
      <Section>
        <Heading>ABOUT ME</Heading>
        <Text>Computer science graduate with practical experience in cloud services, predictive modeling, and real-time data streaming. Proven ability to develop scalable and secure solutions, and enhance data value. Skilled in Python, SQL, and cloud-based technologies, with a strong focus on improving operational efficiency, data quality, and data privacy and security. Knowledgeable in data governance, compliance, and auditing, with an interest in data laws and regulations.</Text>
      </Section>
      <Section>
        <Heading>EDUCATION AND CERTIFICATIONS</Heading>
        <Text><Bold>University of Illinois at Urbana Champaign</Bold> — Champaign, IL</Text>
        <Text>BS. Computer Science</Text>
        <List>
          <ListItem>IAPP Certified Information Privacy Professional, U.S. Private Sector Law (CIPP/US)</ListItem>
          <ListItem>IAPP Certified Information Privacy Technologist (CIPT)</ListItem>
          <ListItem>AWS Certified Cloud Practitioner</ListItem>
        </List>
      </Section>
      <Section>
        <Heading>EXPERIENCE</Heading>
        <SubHeading><Bold>DataAnnotation</Bold> —  Data Annotator and Code Reviewer — New York, NY (Present)</SubHeading>
        <SubText>Quidem vel qui non. Quo ipsa assumenda maiores dolorum dolorum unde earum. Est est qui qui magni. Doloremque dolores pariatur non et totam voluptatum labore. Modi quia quod nemo temporibus corrupti. Dignissimos qui vitae non sit sequi voluptatem quisquam quidem Quidem vel qui non. Quo ipsa assumenda maiores dolorum dolorum unde earum. Est est qui qui magni. Doloremque dolores pariatur non et totam voluptatum labore. Modi quia quod nemo temporibus corrupti. Dignissimos qui vitae non sit sequi voluptatem quisquam quidem</SubText>
        <SubHeading><Bold>Optum, UnitedHealth Group</Bold> — Technology Development Program Intern — Schaumburg, IL (2020,2021)</SubHeading>
        <SubText>Quidem vel qui non. Quo ipsa assumenda maiores dolorum dolorum unde earum. Est est qui qui magni. Doloremque dolores pariatur non et totam voluptatum labore. Modi quia quod nemo temporibus corrupti. Dignissimos qui vitae non sit sequi voluptatem quisquam quidem Quidem vel qui non. Quo ipsa assumenda maiores dolorum dolorum unde earum. Est est qui qui magni. Doloremque dolores pariatur non et totam voluptatum labore. Modi quia quod nemo temporibus corrupti. Dignissimos qui vitae non sit sequi voluptatem quisquam quidem</SubText>
        <SubHeading><Bold>Freelance — Web Designer and Digital Marketer</Bold> — Crystal Lake, IL (2016-2018)</SubHeading>
        <SubText>Quidem vel qui non. Quo ipsa assumenda maiores dolorum dolorum unde earum. Est est qui qui magni. Doloremque dolores pariatur non et totam voluptatum labore. Modi quia quod nemo temporibus corrupti. Dignissimos qui vitae non sit sequi voluptatem quisquam quidem Quidem vel qui non. Quo ipsa assumenda maiores dolorum dolorum unde earum. Est est qui qui magni. Doloremque dolores pariatur non et totam voluptatum labore. Modi quia quod nemo temporibus corrupti. Dignissimos qui vitae non sit sequi voluptatem quisquam quidem</SubText>
      </Section>
    </Container>
  );
}

export default AboutMe;
