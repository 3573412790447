// src/components/Projects.js
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #282c34;
  color: white;
`;

const ProjectCard = styled.div`
  position: relative; // Ensure the pseudo-element is positioned relative to the card
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  height: 200px;
  margin: 10px 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); // Semi-transparent overlay
    border-radius: 10px;
    z-index: 1; // Ensure the overlay is above the background image but below the text
  }
`;

const ProjectTitle = styled.h2`
  position: relative; // Ensure the text is above the overlay
  z-index: 2; // Ensure the text is above the overlay
  text-shadow: 1px 1px 2px black;
`;

function Projects() {
  const projects = [
    { title: 'Site Design Documents (Notion)', url: 'https://project1.example.com', backgroundImage: 'url(https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ffiles.scmagazine.com%2Fwp-content%2Fuploads%2F2023%2F08%2Faws-amazon-web-services-1.jpg&f=1&nofb=1&ipt=fa536fdf3bb105171d6293007b0d6a3a72cfba9a0d2b606671fadfd3e1e02a66&ipo=images)', textColor: '#61dafb' },
    { title: '(Depreciated) Old Portfolio Site Documents', url: 'https://project2.example.com', backgroundImage: 'url(https://wallpapercave.com/wp/wp2754931.jpg)', textColor: '#61dafb' },
    // Add more projects as needed
  ];

  return (
    <Container>
      {projects.map(project => (
        <ProjectCard
          key={project.title}
          style={{ backgroundImage: project.backgroundImage }}
          onClick={() => window.location.href = project.url}
        >
          <ProjectTitle style={{ color: project.textColor }}>{project.title}</ProjectTitle>
        </ProjectCard>
      ))}
    </Container>
  );
}

export default Projects;
