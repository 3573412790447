// src/Sidebar.js
import React, { useState } from 'react';
import { Button } from '@aws-amplify/ui-react';
import Home from './components/Home';
import PrivacySettings from './components/PrivacySettings';
import AboutMe from './components/AboutMe';
import ContactMe from './components/ContactMe';
import Projects from './components/Projects';

const Sidebar = ({ signOut, username, onTabClick }) => {
  const [activeTab, setActiveTab] = useState('Home'); // Default active tab

  const handleClick = (tabName, component) => {
    setActiveTab(tabName);
    onTabClick(component);
  };


  return (
    <div className="sidebar">
      <h2>Hello, {username || 'Guest'}</h2>
      <ul>
        <li className={activeTab === 'Home' ? 'active' : ''} onClick={() => handleClick('Home',<Home />)}>
          <a href="#">Home</a>
        </li>
        <li className={activeTab === 'About Me' ? 'active' : ''} onClick={() => handleClick('About Me',<AboutMe />)}>
          <a href="#">About Me</a>
        </li>
        <li className={activeTab === 'Projects' ? 'active' : ''} onClick={() => handleClick('Projects',<Projects />)}>
          <a href="#">Portfolio</a>
        </li>
        <li className={activeTab === 'Contact Me' ? 'active' : ''} onClick={() => handleClick('Contact Me',<ContactMe />)}>
          <a href="#">Contact Me</a>
        </li>
        <li className={activeTab === 'Privacy Settings' ? 'active' : ''} onClick={() => handleClick('Privacy Settings',<PrivacySettings />)}>
          <a href="#">Privacy Settings</a>
        </li>
      </ul>
      <Button variation="primary" onClick={signOut}>Sign out</Button>
    </div>
  );
};

export default Sidebar;
