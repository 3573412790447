// src/App.js
import React, { useState, useEffect } from 'react';
import { Authenticator, ThemeProvider, defaultDarkModeOverride, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Sidebar from './Sidebar';
import Home from './components/Home';



function App() {
  // State to hold the currently active content
  const [activeContent, setActiveContent] = useState(<Home />);


  const handleContentChange = (component) => {
    setActiveContent(component);
  };
  
  useEffect(() => {
    handleContentChange(<Home />);  // Set Home component as default
  }, []);

  return (
    <ThemeProvider theme={defaultDarkModeOverride}>
      <Authenticator>
        {({ signOut, user }) => (
          <div style={{ display: 'flex', flexDirection: 'row', minHeight: '100vh', width: '100vw' }}>
            <Sidebar signOut={signOut} username={user ? user.signInDetails.loginId.split('@')[0] : null} onTabClick={setActiveContent} />
            <div style={{ flexGrow: 1, padding: '20px', backgroundColor: '#282c34' }}>
              <p>{activeContent}</p>
            </div>
          </div>
        )}
      </Authenticator>
      <footer style={{ color: 'white', textAlign: 'center', position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#333' }}>
        Please review our <a href="https://yourdomain.com/privacy-policy.html" target="_blank" style={{ color: 'lightblue' }}>Privacy Policy</a>.
      </footer>
    </ThemeProvider>
  );
}

export default App;
