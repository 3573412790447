import React, { useState, useEffect } from 'react';
import { Button, SwitchField, TextField, Heading, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import styled from 'styled-components';
import { Authenticator as Auth } from '@aws-amplify/ui-react';

const Container = styled(View)`
  background-color: #282c34;
  color: white;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

const SettingsForm = styled.form`
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #282c39;
  color: white;
  padding: 20px;
  border: 2px solid #dadada;  // Bright green outline for visibility
  border-radius: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
`;

const ModalButton = styled(Button)`
  flex: 1;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

function Settings() {
  const [settings, setSettings] = useState({ message: 'Put a message here! (Look for it in the DSAR)', example_setting: false });
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalAction, setModalAction] = useState('');
  const [apiStatus, setApiStatus] = useState(false); // Track if the API call has been made

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const session = await Auth.currentSession();
        const token = session.idToken.jwtToken;
        const response = await fetch('https://api.mysite.com/settings', {
          headers: { Authorization: `Bearer ${token}` }
        });
        const data = await response.json();
        setSettings({ message: data.message, example_setting: data.example_setting });
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };
    fetchSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const session = await Auth.currentSession();
      const token = session.idToken.jwtToken;
      console.log('Saving settings with:', settings);
      await fetch('https://api.mysite.com/settings', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(settings)
      });
      console.log('Settings saved successfully');
    } catch (error) {
      console.error('Error saving settings:', error);
    }
  };

  const handleModalAction = async (action) => {
    if (action === 'yes') {
      try {
        const response = await fetch(`https://api.mysite.com/${modalAction}`); // Adjust URL as needed
        const result = await response.json();
        setModalContent(`Response: ${result.message}`);
        setApiStatus(true);
      } catch (error) {
        setModalContent(`Error: ${error.message}`);
        setApiStatus(true);
      }
    } else if (action === 'no' || action === 'close') {
      setShowModal(false);
      setApiStatus(false);
    }
  };

  const openConfirmationModal = (actionType) => {
    const confirmationMessages = {
      'access your data': 'Confirm that you would like to be emailed a copy of your data. This may take a few minutes to complete.',
      'delete your data': 'Confirm that you would like to delete your account. You will be emailed a confirmation, and this may take a few minutes to complete.'
    };
    setModalContent(confirmationMessages[actionType]);
    setModalAction(actionType);
    setShowModal(true);
    setApiStatus(false);
  };

  return (
    <Container>
      <Heading level={1} color="var(--amplify-colors-font-secondary)">Settings</Heading>
      <SettingsForm onSubmit={handleSubmit}>
        <TextField label="Secret Message" name="Secret Message" value={settings.message} onChange={handleChange} placeholder="Enter a message" />
        <SwitchField label="example_setting" name="example_setting" checked={settings.example_setting} onChange={handleChange} />
        <Button type="submit" variation="primary">Save Changes</Button>
        <Button variation="link" onClick={() => openConfirmationModal('access your data')}>Access My Data</Button>
        <Button variation="link" onClick={() => openConfirmationModal('delete your data')}>Delete My Account</Button>
      </SettingsForm>
      {showModal && (
        <Modal>
          <p>{modalContent}</p>
          {apiStatus ? (
            <ButtonRow>
              <ModalButton onClick={() => handleModalAction('close')} color="blue">Close</ModalButton>
            </ButtonRow>
          ) : (
            <ButtonRow>
              <ModalButton onClick={() => handleModalAction('yes')} color="green">Yes</ModalButton>
              <ModalButton onClick={() => handleModalAction('no')} color="red">No</ModalButton>
            </ButtonRow>
          )}
        </Modal>
      )}
    </Container>
  );
}

export default Settings;
