// src/components/Home.js
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  color: #EEE;
  background-color: #282c30;
  padding: 40px;
  font-family: 'Georgia', serif;
  max-width: 800px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
`;

const Greeting = styled.h1`
  color: #61dafb;
  font-size: 2em;
`;

const Farewell = styled.p`
  font-style: italic;
  text-align: center;
  margin-top: 20px;
  color: #aad1e6;
`;

const SubHeading = styled.h2`
  color: #aad1e6;
  font-size: 1.5em;
`;

const Text = styled.p`
  line-height: 1.8;
  text-indent: 20px;
`;

const List = styled.ul`
  list-style-type: circle;
  margin: 20px 40px;
  color: #61dafb;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  color: #FFF;
`;

const Highlight = styled.span`
  font-weight: bold;
  color: #ffcc00;
`;

function Home() {
  return (
    <Container>
      <Greeting>Welcome to My Portfolio Site!</Greeting>
      <Text>This homepage will guide you through navigating the site's offerings.</Text>

      <SubHeading>Getting Started</SubHeading>
      <Text>You can always return to these instructions by clicking on the 'Home' tab. If you're reading this, you've already created an account and authenticated your email. The entire site, both backend and frontend, is hosted on AWS. Your identity is managed using AWS Cognito, and your data is stored using various AWS services. Please review the privacy policy for detailed information on data collection and usage. At the end of your visit, explore the <Highlight>'Delete My Account'</Highlight> functionality in the <Highlight>'Privacy Settings'</Highlight> tab. Note that non-member accounts have a very short retention period, so your data will be deleted automatically if you forget.</Text>

      <SubHeading>Site Navigation</SubHeading>
      <List>
        <ListItem><Highlight>About Me:</Highlight> This section is similar to my resume but provides more room to elaborate on my experiences and qualifications.</ListItem>
        <ListItem><Highlight>Portfolio:</Highlight> Here, you’ll find links to various projects and detailed documents outlining the architecture and design of this website.</ListItem>
        <ListItem><Highlight>Contact Me:</Highlight> Feel free to submit feedback via the form. Note that I will respond to the email address provided in the form, and I won't see the account tied to the submission.</ListItem>
        <ListItem><Highlight>Privacy Settings:</Highlight> This tab offers various controls related to your data. As a visitor, I encourage you to explore the <Highlight>'Request My Data'</Highlight> and <Highlight>'Delete My Account'</Highlight> mechanisms. These features demonstrate the technology required to comply with data subject access and removal requests. For your visitor account, these actions will be processed instantly. More formal behaviors like manual review and legal holds exist for member accounts, and you can find more details in the design documents.</ListItem>
      </List>

      <Farewell>Enjoy exploring,<br/>Myles</Farewell>
    </Container>
  );
}

export default Home;