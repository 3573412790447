import React from 'react';

function ContactMe() {
    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px',
            backgroundColor: '#282c34',
            color: 'white',
        },
        section: {
            flex: 1,
            margin: '10px',
            padding: '20px',
            border: '1px solid #333',
            borderRadius: '8px',
            backgroundColor: '#333'
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
        },
        input: {
            marginBottom: '20px',
            padding: '8px',
            backgroundColor: '#444',
            border: 'none',
            color: 'white',
        },
        button: {
            padding: '10px',
            backgroundColor: '#61dafb',
            border: 'none',
            color: 'black',
            cursor: 'pointer',
            borderRadius: '5px',
        },
    };

    // Function to handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        const email = event.target.email.value;
        const message = event.target.message.value;

        // Sending data to the API
        try {
            const response = await fetch('https://bp2skhdplf.execute-api.us-east-1.amazonaws.com/dev-stage/contact', { // Replace 'your_api_endpoint' with your actual API Gateway URL
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, message }),
            });

            if (response.ok) {
                alert('Message sent successfully!');
            } else {
                alert('Failed to send message.');
            }
        } catch (error) {
            console.error('Failed to send message:', error);
            alert('Failed to send message.');
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.section}>
                <h1>Contact Info</h1>
                <p>Text or Voicemail: (224) 523-3119</p>
                <p>Email: mylesgudeman@gmail.com</p>
            </div>
            <div style={styles.section}>
                <h1>Submit Feedback</h1>
                <form style={styles.form} onSubmit={handleSubmit}>
                    <label htmlFor="email">Your Email:</label>
                    <input type="email" id="email" name="email" required style={styles.input} />
                    <label htmlFor="message">Your Message:</label>
                    <textarea id="message" name="message" required style={styles.input}></textarea>
                    <button type="submit">Send</button>
                </form>
            </div>
        </div>
    );
}

export default ContactMe;
